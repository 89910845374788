.navbar {
  background-color: #fff;
  position: fixed;
  padding: 0;
  width: 100%;
  z-index: 999;
}

.navbar-toggler {
  padding: 0;
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  outline: 0;
}

.navbar-header {
  height: 60px;
  padding: 15px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.navbar-header .user {
  display: flex;
  font-weight: 600;
  font-size: 12px;
  align-items: center;
}

.navbar-header .user i {
  margin-left: 5px;
}

.nav-link {
  font-size: 18px;
}

.nav-link.active {
  color: #4858e9;
}

.navbar-collapse {
  height: calc(100vh - 60px);
}

.collapse.show {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.navbar-nav {
  padding: 50px 15px 15px 15px;
  flex-grow: 1;
}

.navbar-footer {
  padding: 25px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.navbar-footer a {
  color: #000000;
  font-size: 14px;
  font-weight: 600;
}

.logo {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: 50px;
}
