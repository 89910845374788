.invoice {
  border-radius: 17px;
  background-color: #ffffff;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12);
}

.invoice .padding {
  padding: 10px 15px;
}

.invoice .divider {
  border-bottom: 1px solid #ff00ff;
}

.invoice .header {
  font-size: 10px;
  text-transform: uppercase;
}

.invoice .title {
  font-size: 14px;
  font-weight: 500;
}

.invoice .amount {
  color: #4e5457;
  font-size: 12px;
}

.invoice .id {
  font-size: 12px;
  font-weight: 500;
}

.invoice .label {
  color: #4e5457;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 300;
}

.invoice .value {
  color: #000000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
}

.invoice-single .table {
  font-size: 12px;
}

/* Use hardcoded widths for now to stop table exploding on smaller screen */
.new-invoice .description {
  max-width: 160px;
}

.new-invoice .amount {
  max-width: 55px;
}

@media only screen and (min-width: 600px) {
  .invoice-single .table {
    font-size: 14px;
  }
}
