.bondr-card {
  background-color: #fff;
  border-radius: 17px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12);
  position: relative;
}

.bondr-card .title {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
}

.bondr-card .name {
  color: #4e5457;
  font-size: 14px;
}

.bondr-card .favourites-star {
  position: absolute;
  right: 5px;
  top: 5px;
}
